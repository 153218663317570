<template>
    <div class="main-box">
        <a-form :model="settings" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="ec-box">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="用户名">
                            <a-input v-model:value="userName" :disabled="true" placeholder="请输入用户名" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="最新密码">
                            <a-input type="password" v-model:value="newPwd" placeholder="请输入最新密码" />
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="确认密码">
                            <a-input type="password" v-model:value="conPwd" placeholder="请输入确认密码" />
                        </a-form-item>
                    </div>
                </div>
                <div class="contents">
                    <a-button @click="onSubmit">更新</a-button>
                    <p style="margin-top: 1em;">修改注册手机号请联系平台客服：<span style="color: #4762C9;">0451-81023000/18245108077</span></p>
                </div>
            </div>
        </a-form>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters(['userId', 'userName'])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            newPwd: '',
            uName: '',
            conPwd: ''
        }
    },
    methods: {
        onSubmit () {
            if (this.newPwd === '') {
                this.$message.info('最新密码不能为空！')
                return false
            } else if (this.newPwd !== this.conPwd) {
                this.$message.info('两次输入的密码不一致！')
                return false
            }
            this.$store.dispatch('modifyPwd', { USER_ID: this.userId, PASSWORD: this.newPwd, USERNAME: this.userName, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改密码成功，请您牢记密码！')
                    this.newPwd = ''
                    this.conPwd = ''
                } else {
                    this.$message.error('系统异常，修改密码失败，请稍后重试！')
                    this.newPwd = ''
                    this.conPwd = ''
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .ec-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 100%;
            }
            .con-item-download{
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
                a{
                    font-size: @font-lg;
                    display: inline-block;
                    text-decoration: underline;
                    color: @color-ff;
                    padding: 10px 60px;
                    background: @color-blue;
                }
            }
            .con-item-vercode{
                .ant-form-item{
                    .ant-input{
                        width: 55%;
                    }
                    .ant-btn{
                        width: 40%;
                        margin-left: 5%;
                    }
                }
            }
            .con-item-upload{
                width: 20%;
                margin-bottom: 20px;
                ::v-deep(.ant-upload) {
                    width: 100%;
                }
                ::v-deep(.ant-btn){
                    width: 100%;
                }
            }
            .con-item-uploadimg{
                width: 30%;
                ::v-deep(.ant-upload){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-item){
                    width: 240px;
                    height: 195px;
                }
                ::v-deep(.ant-upload-list-picture-card-container){
                    height: 195px;
                }
            }
        }
        .contents{
            text-align: center;
            ::v-deep(.ant-form-item-control-wrapper){
                margin: 0 auto;
                text-align: center;
            }
            .ant-btn{
                padding: 0 50px;
                height: 40px;
                color: @color-ff;
                background: @color-blue;
            }
        }
    }
}
</style>
